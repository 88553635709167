<template>
  <div class="codePage">
    <div class="mask">
      <div class="navbar">
        <div @click="$router.go(-1)">
          <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
        </div>
        <div>推广赚钱</div>
        <router-link class="rightBtn" tag="div" to="/promotionRecord">推广记录</router-link>
      </div>
      <div class="body">

        <div class="bgBox">
          <div class="codeBox">
            <ImgDecypt :src="uInfo.avatarUrl" round class="avatar" />
            <div class="codeTitle">您的推广码</div>
            <div class="promotion_code">{{uInfo.inviteCode}}</div>
            <div class="qrcode" ref="qrCodeUrl"></div>
            <div class="promotionUrl">{{uInfo.inviteUrl}}</div>
          </div>
          <div class="btnBox">
            <div class="btn">
              <svg-icon iconClass="download" class="btn_svg"></svg-icon><span>截图保存分享</span>
            </div>
            <div class="btn" @click="copyUrl">
              <svg-icon iconClass="share" class="btn_svg"></svg-icon><span>复制链接分享</span>
            </div>
          </div>
          <div class="titleBox">
            <svg-icon class="title_left" iconClass="ic_agent_ttitle"></svg-icon>
            <div class="title">邀请步骤</div>
            <svg-icon class="title_right" iconClass="ic_agent_ttitle"></svg-icon>
          </div>
          <div class="tip">邀请1人可获得2天体验会员</div>
          <!-- <div></div> -->
          <!-- <div style="margin-bottom: 100px;"></div> -->
          <div class="step">
            <img src="@/assets/png/personal_rule.png">
          </div>
          <!-- <img class="step" src="@/assets/img/step.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import QRCode from "qrcodejs2";
  export default {
    data() {
      return {
        uInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      };
    },
    created() {
      this.generateCode();
    },
    methods: {
      // 生成二维码
      generateCode() {
        this.$nextTick(() => {
          this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: this.uInfo.inviteUrl,
            width: ((window.innerWidth > 600 ? 600 : window.innerWidth) / 375) * 168 +
              "",
            height: ((window.innerWidth > 600 ? 600 : window.innerWidth) / 375) * 168 +
              "",
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.L,
          });
        });
      },
      // 复制
      copyUrl() {
        this.$copyText(this.uInfo.inviteUrl).then(
          () => {
            this.$toast('复制成功');
          },
          () => {
            this.$toast('复制失败，请手动复制');
          }
        );
      },
    },
  };
</script>

<style lang='scss' scoped>
  .codePage {
    height: 100vh;
    background-image: url("../../../../assets/png/acount_bg.png");
    background-attachment: fixed;
    background-size: cover;

    .mask {
      background: rgba(0, 0, 0, 0.368);
    }

    .navbar {
      // position: fixed;
      // width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background: $vermillion;
      color: $white1;
      font-size: 16px;
      font-weight: 600;

      .rightBtn {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .body {
      height: 100vh;
      overflow: auto;
      -ms-overflow-style: none;
    }

    .bgBox {
      .codeBox {
        width: 307px;
        height: 419px;
        background: url("../../../../assets/png/codeBg.png");
        background-size: 100% 100%;
        margin: 61px auto 0;
        padding: 45px 0 0;
        box-sizing: border-box;
        position: relative;
        text-align: center;

        .avatar {
          width: 64px;
          height: 64px;
          position: absolute;
          top: -32px;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 50%;
          border: 4px solid $white1;
          // background-image: url("https://up.enterdesk.com/edpic_360_360/17/e1/07/17e107a65010e76284a7174d34261103jpg");
          background-size: 100% 100%;
        }

        .codeTitle {
          font-size: 16px;
          height: 22px;
          line-height: 22px;
          margin-top: 10px;
          // margin-top: 45px;
        }

        .promotion_code {
          height: 49px;
          line-height: 49px;
          margin-top: 13px;
          font-size: 35px;
          color: $vermillion;
        }

        .qrcode {
          width: 168px;
          height: 168px;
          margin: 34px auto 10px;
        }

        .promotionUrl {
          font-size: 16px;
          color: $greyishBrown;
          margin-top: 10px;
        }
      }
    }

    .btnBox {
      font-size: 15px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      color: $white1;

      .btn {
        width: 151px;
        height: 38px;
        background: $vermillion;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;

        .btn_svg {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }

    .titleBox {
      height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 24px 23px;

      .title {
        font-size: 24px;
        color: $apricot;
      }

      .title_left {
        width: 105px;
        height: 20px;
      }

      .title_right {
        width: 105px;
        height: 20px;
        transform: rotate(180deg);
      }
    }

    .tip {
      color: $whiteTow;
      font-size: 16px;
      text-align: center;
      margin-bottom: 24px;
    }

    .step {
      width: 270px;
      margin: 0 auto 100px;

      img {
        width: 100%;
      }
    }
  }
</style>